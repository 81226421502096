import { Button, Card, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { getConfig, setConfig } from '../../api';
import GeneralFunctions from '../../components/generalFunctions/GeneralFunctions';
import NodeConfig from '../../components/nodeConfig/NodeConfig';
import RPCConfig from '../../components/rpcConfig/RPCConfig';
import {
  configInBrowserState,
  nodeAddressState,
  rpcConfigState,
} from '../../context/appContext';
import {
  parseNodeConfigBooleans,
  stringifyNodeConfigBooleans,
} from '../../helpers/nodeConfigHelpers';
import { RpcConfig, RpcConfigEntry } from '../../context/appContextInterfaces';

function NodeConfigPage() {
  const [appStateRpcConfig, setAppStateRpcConfig] =
    useRecoilState(rpcConfigState);
  const [configInBrowser, setConfigInBrowser] =
    useRecoilState(configInBrowserState);
  const [nodeConfigForm, setNodeConfigForm] = useState<any>(null);
  const [rpcConfigForm, setRpcConfigForm] = useState<RpcConfig>({});
  const [nodeAddress, setNodeAddress] = useRecoilState(nodeAddressState);

  useEffect(() => {
    console.log('configInBrowser', configInBrowser);
    setNodeConfigForm(configInBrowser);
    console.log('rpcConfig', appStateRpcConfig);
    setRpcConfigForm(appStateRpcConfig);
  }, [configInBrowser]);

  const handleGetConfig = async () => {
    let res;
    try {
      console.log('nodeAddress', nodeAddress);
      res = await getConfig(nodeAddress);
    } catch (e: any) {
      console.error(e);
      alert(
        'error getting config: ' +
          e.message +
          ' - ' +
          e.response?.data?.error +
          ' - ' +
          e.response?.data?.reason
      );
      throw e;
    }
    const { data } = res;
    const { config, chains: rpcConfig } = data;

    // sort the config by keyname so that it's consistent otherwise it comes out in a diff order everytime which is super confusing IMO
    const orderedConfig = Object.keys(config)
      .sort()
      .reduce((obj: { [key: string]: string }, key) => {
        //@ts-ignore
        obj[key] = config[key];
        return obj;
      }, {});

    // // TODO: remove when backend can handle booleans
    const setBooleans = parseNodeConfigBooleans(orderedConfig);
    setConfigInBrowser(setBooleans);
    setAppStateRpcConfig(rpcConfig as RpcConfig);
  };

  const handleSetConfig = async () => {
    let res;
    try {
      // TODO: remove when backend can handle booleans
      console.log('nodeConfigForm', nodeConfigForm);
      setConfigInBrowser(nodeConfigForm);
      const stringifiedNodeConfig = stringifyNodeConfigBooleans(nodeConfigForm);
      // Prune null values in rpcConfigForm
      const prunedRpcConfigForm = removeNullValues(rpcConfigForm);

      res = await setConfig(
        nodeAddress,
        stringifiedNodeConfig,
        prunedRpcConfigForm
      );
    } catch (e: any) {
      alert(
        'error setting config: ' +
          e.message +
          ' - ' +
          e.response.data.error +
          ' - ' +
          e.response.data.reason
      );
      throw e;
    }

    const { data } = res;
    console.log('data', data);
  };

  const handleExportRpcConfig = async () => {
    let res;
    try {
      // Prune null values in rpcConfigForm
      const prunedRpcConfigForm = removeNullValues(rpcConfigForm);

      const rpcConfig = JSON.stringify(prunedRpcConfigForm, null, 2);
      console.log('rpcConfig', rpcConfig);
      const element = document.createElement('a');
      const file = new Blob([rpcConfig], {
        type: 'application/json',
      });
      element.href = URL.createObjectURL(file);
      element.download = 'lit-rpc-config.json';
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    } catch (e: any) {
      alert(
        'error exporting RPC config: ' +
          e.message +
          ' - ' +
          e.response.data.error +
          ' - ' +
          e.response.data.reason
      );
      throw e;
    }
  };

  const handleImportRpcConfig = async () => {
    var input = document.createElement('input');
    input.type = 'file';
    input.accept = '.json';

    input.onchange = (e) => {
      // getting a hold of the file reference
      // @ts-ignore
      var file = e.target.files[0];

      // setting up the reader
      var reader = new FileReader();
      reader.readAsText(file, 'UTF-8');

      // here we tell the reader what to do when it's done reading...
      reader.onload = (readerEvent) => {
        // @ts-ignore
        var content = readerEvent.target.result; // this is the content!
        console.log(content);
        // @ts-ignore
        setRpcConfigForm(JSON.parse(content));
      };
    };

    input.click();
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={24}>
        <Typography sx={{ mb: 2 }} variant={'h5'} align={'center'}>
          - Node Config -
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <GeneralFunctions />
      </Grid>
      <Grid item sm={12}>
        <Card>
          <Stack
            sx={{
              width: '100%',
              p: 2,
            }}
            direction={'row'}
            justifyContent={'space-between'}
            spacing={2}
          >
            <Typography variant={'h5'}>Config Options</Typography>
            <Stack
              sx={{ pr: '2em' }}
              direction={'row'}
              justifyContent={'flex-end'}
              spacing={2}
            >
              <Button onClick={handleGetConfig}>Get Config</Button>
              <Button variant={'contained'} onClick={handleSetConfig}>
                Set Config
              </Button>
              <Button variant={'contained'} onClick={handleExportRpcConfig}>
                Export RPC Config
              </Button>
              <Button variant={'contained'} onClick={handleImportRpcConfig}>
                Import RPC Config
              </Button>
            </Stack>
          </Stack>
          <NodeConfig
            nodeConfigForm={nodeConfigForm}
            setNodeConfigForm={setNodeConfigForm}
            handleGetConfig={handleGetConfig}
            handleSetConfig={handleSetConfig}
            handleExportRpcConfig={handleExportRpcConfig}
            handleImportRpcConfig={handleImportRpcConfig}
          />
          {!!rpcConfigForm && (
            <RPCConfig
              rpcConfig={rpcConfigForm}
              setRpcConfig={setRpcConfigForm}
            />
          )}
        </Card>
      </Grid>
      {!configInBrowser && !rpcConfigForm && (
        <Stack
          sx={{
            height: '500px',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant={'h5'} color={'grey'}>
            No Config Loaded
          </Typography>
        </Stack>
      )}
    </Grid>
  );
}

function removeNullValues(rpcConfig: RpcConfig): RpcConfig {
  const prunedRpcConfig: RpcConfig = {};
  Object.entries(rpcConfig).forEach(([networkKey, rpcConfigEntries]) => {
    // @ts-ignore
    prunedRpcConfig[networkKey] = rpcConfigEntries.map((rpcConfigEntry) => {
      const cleanRpcConfigEntry: RpcConfigEntry = {
        url: rpcConfigEntry.url,
      };
      Object.keys(rpcConfigEntry).forEach((key) => {
        if (rpcConfigEntry[key as keyof RpcConfigEntry] !== null) {
          // @ts-ignore
          cleanRpcConfigEntry[key] =
            rpcConfigEntry[key as keyof RpcConfigEntry];
        }
      });
      return cleanRpcConfigEntry;
    });
  });

  return prunedRpcConfig;
}

export default NodeConfigPage;

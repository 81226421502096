import { useEffect } from 'react';
import {
  Card,
  CardContent,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useRecoilState } from 'recoil';
import {
  contractAddressErrorState,
  contractAddressesState,
  litTokenContractAddressState,
} from '../../context/appContext';
import getLitTokenContractAddress, {
  networkNames,
  networks,
  chainNames,
} from './utils';
import {
  switchToChronicleNetwork,
  switchToVesuviusNetwork,
  switchToYellowstoneNetwork,
} from '../../lib/web3';

function SetContractAddresses() {
  const [stakingContractAddresses, setStakingContractAddresses] =
    useRecoilState(contractAddressesState);
  const [litTokenContractAddress, setLitTokenContractAddress] = useRecoilState(
    litTokenContractAddressState
  );
  const [contractAddressError, _] = useRecoilState(contractAddressErrorState);

  useEffect(() => {
    // we now set the default in appContext.ts instead of here.
    // setStakingContractAddresses(networks.manzano);
    if (stakingContractAddresses.chain.name === chainNames.CHRONICLE) {
      switchToChronicleNetwork();
    } else if (stakingContractAddresses.chain.name === chainNames.VESUVIUS) {
      switchToVesuviusNetwork();
    } else if (stakingContractAddresses.chain.name === chainNames.YELLOWSTONE) {
      switchToYellowstoneNetwork();
    }
  }, [stakingContractAddresses]);

  useEffect(() => {
    if (stakingContractAddresses.stakingAddress !== '') {
      // not Custom network
      (async () => {
        setLitTokenContractAddress(
          await getLitTokenContractAddress(stakingContractAddresses)
        );
      })();
    } else {
      setLitTokenContractAddress('');
    }
  }, [stakingContractAddresses]);

  function updateStakingContractAddresses(event: SelectChangeEvent) {
    setStakingContractAddresses(
      networks[event.target.value as keyof typeof networks]
    ); // Only used in the below Select component
  }

  function handleStakingAddressChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setStakingContractAddresses({
      ...stakingContractAddresses,
      stakingAddress: event.target.value,
    });
  }

  return (
    <Card>
      <CardContent>
        <Stack spacing={2} sx={{ m: 2 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant={'h5'}>Choose Network</Typography>
            <Select
              value={stakingContractAddresses.network}
              onChange={updateStakingContractAddresses}
              displayEmpty
              sx={{ width: '8em' }}
            >
              {Object.values(networkNames).map((networkName) => (
                <MenuItem value={networkName}>{networkName}</MenuItem>
              ))}
            </Select>
          </div>
          {stakingContractAddresses.network === networkNames.CUSTOM && (
            <Stack spacing={2} direction={'row'} sx={{ m: 2 }}>
              <TextField
                sx={{ flexGrow: '1', flexShrink: '1' }}
                size={'small'}
                value={stakingContractAddresses.stakingAddress}
                onChange={handleStakingAddressChange}
                label={'Staking Contract Address'}
                placeholder={'eg. 0xf899FcCE61F48211aa9b36Dc39093951E6028698'}
              />
              <TextField
                sx={{ flexGrow: '1', flexShrink: '1' }}
                size={'small'}
                value={litTokenContractAddress}
                onChange={(e) => setLitTokenContractAddress(e.target.value)}
                label={'Lit Token Contract Address'}
                placeholder={'eg. 0x74eD0E2951Cc0d56C9595A19b26AC4CCC376E2F6'}
              />
              {/*<Button*/}
              {/*  variant={'contained'}*/}
              {/*  disabled={_litTokenContractAddress === litTokenContractAddress && _stakingContractAddress === stakingContractAddress}*/}
              {/*  onClick={handleUpdateContractAddresses}>Save</Button>*/}
            </Stack>
          )}
          <Typography color={'error'}>{contractAddressError}</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default SetContractAddresses;

import { ethers } from 'ethers';
import LitTokenABI from '../abis/LITToken.json';
import StakingABI from '../abis/Staking.json';
import StakingBalancesABI from '../abis/StakingBalances.json';
import { networks } from '../components/setContractAddresses/utils';
import { getSigner } from '../lib/web3';
import { addressSchema } from '../models/schema';
import StakeAndJoinNodeRequest from '../models/StakeAndJoinNodeRequest';
import UpdateValidatorInfoRequest from '../models/UpdateValidateInfoRequest';
import { ip2int, int2ip, getAdjustedGas } from '../utils';

export const stakeAndSetInfo = async (
  request: StakeAndJoinNodeRequest,
  stakingContractAddress: string,
  litTokenContractAddress: string
) => {
  // Validation
  try {
    await request.validate();
    await addressSchema.validate(stakingContractAddress);
    await addressSchema.validate(litTokenContractAddress);
  } catch (e: any) {
    throw new Error(`Error validating stake node request: ${e.message}`);
  }

  const signer = await getSigner();

  const stakingContract = new ethers.Contract(
    stakingContractAddress,
    StakingABI,
    signer
  );

  const litTokenContract = new ethers.Contract(
    litTokenContractAddress,
    LitTokenABI,
    signer
  );

  const stakingBalancesAddress =
    await stakingContract.getStakingBalancesAddress();
  const stakingBalancesContract = new ethers.Contract(
    stakingBalancesAddress,
    StakingBalancesABI,
    signer
  );

  // stake
  const amountToStake = await stakingBalancesContract.minimumStake();
  console.log('amountToStake: ', amountToStake);

  // Get address of the connected wallet.
  const connectedStakerAddress = await signer.getAddress();

  // Get balance of LIT token for the connected wallet.
  const litTokenBalance = await litTokenContract.balanceOf(
    connectedStakerAddress
  );
  console.log(`balance for ${connectedStakerAddress}: `, litTokenBalance);

  // Validate if the connected wallet has enough LIT tokens to stake.
  if (litTokenBalance.lt(amountToStake)) {
    throw new Error(
      `Not enough LIT tokens to stake. You need at least ${amountToStake} LIT tokens.`
    );
  }

  // Approve stakingPage contract to spend LIT tokens.
  const approveTx = await litTokenContract.approve(
    stakingBalancesAddress,
    amountToStake
  );
  console.log('approveTx: ', approveTx);

  await approveTx.wait();

  // Stake tokens.
  console.log('Staking tokens...');
  let tx = await stakingContract.stake(ethers.BigNumber.from(request.amount));
  console.log('stake tx for wallet: ', tx);
  await tx.wait();

  // set node info
  tx = await stakingContract.setIpPortNodeAddressAndCommunicationPubKeys(
    ethers.BigNumber.from(ip2int(request.ip)),
    ethers.BigNumber.from(ip2int(request.ipv6)), // Not needed for now
    ethers.BigNumber.from(request.port),
    request.nodeAddress,
    ethers.BigNumber.from(request.senderPubKey),
    ethers.BigNumber.from(request.receiverPubKey)
  );
  console.log(
    'setIpPortNodeAddressAndCommunicationPubKeys tx for wallet: ',
    tx
  );
  await tx.wait();
};

export const requestToLeave = async (stakingContractAddress: string) => {
  // Validation
  try {
    await addressSchema.validate(stakingContractAddress);
  } catch (e: any) {
    throw new Error(`Error validating request: ${e.message}`);
  }

  const signer = await getSigner();

  const stakingContract = new ethers.Contract(
    stakingContractAddress,
    StakingABI,
    signer
  );

  const txData = await stakingContract.populateTransaction.requestToLeave();

  const _adjustedGas = await getAdjustedGas({ signer, txData });

  const tx = await stakingContract.requestToLeave({
    gasLimit: _adjustedGas,
  });

  console.log('requestToLeave tx for wallet: ', tx);
};

export const requestToJoin = async (stakingContractAddress: string) => {
  // Validation
  try {
    await addressSchema.validate(stakingContractAddress);
  } catch (e: any) {
    throw new Error(`Error validating request: ${e.message}`);
  }

  const signer = await getSigner();
  const stakingAddress = await signer.getAddress();

  const stakingContract = new ethers.Contract(
    stakingContractAddress,
    StakingABI,
    signer
  );

  const existingInfo = await stakingContract.validators(stakingAddress);
  console.log('existingInfo: ', existingInfo);

  if (
    existingInfo.nodeAddress === '0x0000000000000000000000000000000000000000'
  ) {
    throw new Error(
      'Node address cannot be zeroes. Please check your staker wallet connection.'
    );
  }

  const txData = await stakingContract.populateTransaction.requestToJoin(
    existingInfo.ip,
    existingInfo.ipv6,
    existingInfo.port,
    existingInfo.nodeAddress,
    existingInfo.senderPubKey,
    existingInfo.receiverPubKey
  );

  const _adjustedGas = await getAdjustedGas({ signer, txData });

  const tx = await stakingContract.requestToJoin(
    existingInfo.ip,
    existingInfo.ipv6,
    existingInfo.port,
    existingInfo.nodeAddress,
    existingInfo.senderPubKey,
    existingInfo.receiverPubKey,
    {
      gasLimit: _adjustedGas,
    }
  );

  console.log('requestToJoin tx for wallet: ', tx);
  await tx.wait();
};

export const unstakeAndGetRewards = async (stakingContractAddress: string) => {
  // Validation
  try {
    await addressSchema.validate(stakingContractAddress);
  } catch (e: any) {
    throw new Error(`Error validating request: ${e.message}`);
  }

  const signer = await getSigner();

  const stakingContract = new ethers.Contract(
    stakingContractAddress,
    StakingABI,
    signer
  );

  const txData = await stakingContract.populateTransaction.exit();
  const _adjustedGas = await getAdjustedGas({ signer, txData });

  const tx = await stakingContract.exit({
    gasLimit: _adjustedGas,
  });
  console.log('exit tx for wallet: ', tx);
};

export const setIpPortNodeAddressAndCommunicationPubKeys = async (
  request: UpdateValidatorInfoRequest,
  stakingContractAddress: string
) => {
  // Validation
  try {
    await request.validate();
    await addressSchema.validate(stakingContractAddress);
  } catch (e: any) {
    throw new Error(
      `Error validating setIpPortNodeAddressAndCommunicationPubKeys request: ${e.message}`
    );
  }

  const signer = await getSigner();

  const stakingContract = new ethers.Contract(
    stakingContractAddress,
    StakingABI,
    signer
  );

  const txData =
    await stakingContract.populateTransaction.setIpPortNodeAddressAndCommunicationPubKeys(
      ethers.BigNumber.from(ip2int(request.ip)),
      ethers.BigNumber.from(ip2int(request.ipv6)), // Not needed for now
      ethers.BigNumber.from(request.port),
      request.nodeAddress,
      ethers.BigNumber.from(request.senderPubKey),
      ethers.BigNumber.from(request.receiverPubKey)
    );

  const _adjustedGas = await getAdjustedGas({ signer, txData });

  const tx = await stakingContract.setIpPortNodeAddressAndCommunicationPubKeys(
    ethers.BigNumber.from(ip2int(request.ip)),
    ethers.BigNumber.from(ip2int(request.ipv6)), // Not needed for now
    ethers.BigNumber.from(request.port),
    request.nodeAddress,
    ethers.BigNumber.from(request.senderPubKey),
    ethers.BigNumber.from(request.receiverPubKey),
    {
      gasLimit: _adjustedGas,
    }
  );
  console.log(
    'setIpPortNodeAddressAndCommunicationPubKeys tx for wallet: ',
    tx
  );
};

export const addAlias = async (addAliasAddress: string) => {
  const stakingBalancesContractAddress =
    networks.manzano.stakingBalancesAddress;
  const signer = await getSigner();

  const stakingBalancesContract = new ethers.Contract(
    stakingBalancesContractAddress,
    StakingBalancesABI,
    signer
  );

  console.log('stakingBalancesContractAddress');
  console.log(stakingBalancesContractAddress);

  const txData = await stakingBalancesContract.populateTransaction.addAlias(
    addAliasAddress
  );

  const _adjustedGas = await getAdjustedGas({ signer, txData });

  const tx = await stakingBalancesContract.addAlias(addAliasAddress, {
    gasLimit: _adjustedGas,
  });

  console.log('addAlias tx for wallet: ', tx);
};

export const removeAlias = async (removeAliasAddress: string) => {
  const stakingBalancesContractAddress =
    networks.manzano.stakingBalancesAddress;
  const signer = await getSigner();

  const stakingBalancesContract = new ethers.Contract(
    stakingBalancesContractAddress,
    StakingBalancesABI,
    signer
  );

  const txData = await stakingBalancesContract.populateTransaction.removeAlias(
    removeAliasAddress
  );

  const _adjustedGas = await getAdjustedGas({ signer, txData });

  const tx = await stakingBalancesContract.removeAlias(removeAliasAddress, {
    gasLimit: _adjustedGas,
  });

  console.log('removeAlias tx for wallet: ', tx);
};

export const getNodeInfo = async (
  stakingContractAddress: string,
  stakingBalancesContractAddress: string,
  stakingWalletAddress: string
) => {
  // Validation
  try {
    await addressSchema.validate(stakingContractAddress);
  } catch (e: any) {
    throw new Error(`Error validating request: ${e.message}`);
  }

  const signer = await getSigner();

  const stakingContract = new ethers.Contract(
    stakingContractAddress,
    StakingABI,
    signer
  );

  const existingInfo = await stakingContract.validators(stakingWalletAddress);
  console.log('existingInfo', existingInfo);
  // format stuff properly
  const formattedNodeInfo = {
    ip: int2ip(existingInfo.ip),
    ipv6: existingInfo.ipv6.toString(), // Not needed for now
    port: existingInfo.port.toString(),
    nodeAddress: existingInfo.nodeAddress.toString(),
    senderPubKey: existingInfo.senderPubKey.toString(),
    receiverPubKey: existingInfo.receiverPubKey.toString(),
  };

  const inCurrentEpoch = await stakingContract.isActiveValidator(
    stakingWalletAddress
  );
  const allValidatorsInNextEpoch =
    await stakingContract.getValidatorsInNextEpoch();
  const inNextEpoch = allValidatorsInNextEpoch.some(
    (a: string) => a.toLowerCase() == stakingWalletAddress.toLowerCase()
  );

  const stakingBalancesContract = new ethers.Contract(
    stakingBalancesContractAddress,
    StakingBalancesABI,
    signer
  );

  const amountStaked = (
    await stakingBalancesContract.balanceOf(stakingWalletAddress)
  ).toString();
  const reward = (
    await stakingBalancesContract.rewardOf(stakingWalletAddress)
  ).toString();
  const minStake = (await stakingBalancesContract.minimumStake()).toString();
  const maxStake = (await stakingBalancesContract.maximumStake()).toString();

  return {
    nodeInfo: formattedNodeInfo,
    inCurrentEpoch,
    inNextEpoch,
    amountStaked,
    reward,
    minStake,
    maxStake,
  };
};
